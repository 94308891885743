import { Subscription } from 'rxjs'
// Angular
import {
	Component,
	OnDestroy,
	OnInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef
} from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
// Layout
import { LayoutConfigService, SplashScreenService } from './core/_base/layout'
// Metronic
import { TranslationService } from './core/_base/metronic'
// language list
import { locale as thLang } from './core/_config/i18n/th'
import { CoreService } from './core/services/core.service';
import { SpinnerService } from './core/services/spinner.service';
import { isNullOrUndefined } from '@swimlane/ngx-datatable/release/utils'
import { ViewRef_ } from '@angular/core/src/view'
import * as Sentry from '@sentry/browser';
import { environment } from '@env/environment'
import { SentryList } from './core/sentry-list'

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'body[kt-root]',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
	// Public properties
	title = 'DW Services'
	loader: boolean
	loading = false
	private unsubscribe: Subscription[] = [] // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param translationService: TranslationService
	 * @param router: Router
	 * @param layoutConfigService: LayoutCongifService
	 * @param splashScreenService: SplashScreenService
	 */
	constructor(
		private translationService: TranslationService,
		private router: Router,
		private layoutConfigService: LayoutConfigService,
		private splashScreenService: SplashScreenService,
		public coreService: CoreService,
		public sprinnerService: SpinnerService,
		public cdr: ChangeDetectorRef
	) {
		// register translations
		this.translationService.loadTranslations(thLang)
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		// enable/disable loader
		this.loader = this.layoutConfigService.getConfig('loader.enabled')
		const routerSubscription = this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				// hide splash screen
				this.splashScreenService.hide()

				// scroll to top on every route change
				window.scrollTo(0, 0)
			}
		})
		this.setSpinner()
		this.unsubscribe.push(routerSubscription)

		// if (environment.production) {
			Sentry.init({
				dsn: environment.sentryDsn,
				environment: environment.production ? 'production' : 'development',
				tracesSampleRate: 0.1,
				maxBreadcrumbs: 50,
				maxValueLength: 250,
				beforeSend(event) {
					delete event.request.headers;
					delete event.request.query_string;
					delete event.breadcrumbs;
    				delete event.user; 
					if (event.extra && event.extra.apiUrl) {
						if (SentryList.includes(event.extra.apiUrl as string)) {
							// if (event.request.data) {
							// 	if (event.request.data.length > 1000) {
							// 		event.request.data = event.request.data.slice(0, 1000);
							// 	} else {
							// 		event.request.data = event.request.data;
							// 	}
							// }
							event.extra = { ...event.extra, timestamp: new Date().toISOString()}
							return event
						}
					}
					return null;  
				}
			});
		// }
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.unsubscribe.forEach(sb => sb.unsubscribe())
	}

	setSpinner() {
		this.sprinnerService.getSpinner().subscribe(
			store => {
				this.loading = store
				this.detectChanges()
			}
		)
	}

	detectChanges() {
    setTimeout(() => {
      if (!isNullOrUndefined(this.cdr) &&
        !(this.cdr as ViewRef_).destroyed) {
          this.cdr.detectChanges()
      }
    }, 100);
  }
}
