import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as Sentry from '@sentry/browser';
import { catchError } from 'rxjs/operators';
import { SentryList } from './sentry-list';
import { environment } from '@env/environment';

@Injectable()
export class SentryInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const apiUrl = request.url;
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          if (SentryList.includes(apiUrl) && environment.production) {
            const customError = {
              message: `Error from API: ${apiUrl}`,
              status: error.status,
              url: apiUrl,
              responseBody: error.error,
            };
            Sentry.captureException(customError, {
              extra: {
                apiUrl: apiUrl,
                status: error.status,
                response: error.message,
              }
            });
          }
          return Observable.throw(error)
        }
      })
    );
  }
}